import {Controllers} from "./controllers";

/**
 * Removes the remaining "#" from the URL.
 * @param {HTMLElement} element
 * @return {function(): *}
 */
function ClearHashController(element) {
  function handleClick() {
    const url = window.location.href.split("#")[0];
    window.setTimeout(() => {
      window.history.replaceState({}, window.title, url);
    });
  }
  element.addEventListener("click", handleClick, {passive: true});

  return () => element.removeEventListener("click", handleClick);
}

Controllers.register("clear-hash", ClearHashController);

import Sortable from "sortablejs";

import {Controllers} from "./controllers";

// load plugins
// Sortable.mount(new AutoScroll());

/**
 * Team editor controller
 * @param {HTMLFormElement} form
 * @constructor
 */
function TeamEditorController(form) {
  const defaultSelectedClass = "selected";
  const defaultHighlightClass = "highlighted";

  const templateKey = /__([a-z_]+)__/g;

  function RaiderList(list) {
    const {selectedClass = defaultSelectedClass} = list.dataset;

    new Sortable(list, {
      group: {
        name: "members",
        pull: "clone",
        put: false, // Do not allow items to be put into this list
      },
      fallbackTolerance: 3, // So that we can select items on mobile
      animation: 150,
      sort: false,

      // setData: function (transfer, element) {
      //   console.info(transfer, element);
      // },
      // onMove: function (event) {
      //   console.info(event);
      // },

      multiDrag: true, // Enable multi-drag
      selectedClass, // The class applied to the selected items
    });
  }

  function MemberList(list) {
    const {
      prefix,
      highlightClass = defaultHighlightClass,
      selectedClass = defaultSelectedClass,
    } = list.dataset;
    if (!prefix) {
      console.warn("MemberList: Missing data-prefix ", list);
      return;
    }

    const template = list.querySelector("template");
    if (!template) {
      console.warn("MemberList: No template element found in ", list);
      return;
    }

    const totalFormsInput = document.querySelector(`[name="${prefix}-TOTAL_FORMS"]`);

    function createItem(fields) {
      const fragment = template.content.cloneNode(true);
      const element = fragment.firstElementChild;

      // hard set the data-attributes
      Object.entries(fields).forEach(([key, value]) =>
        element.setAttribute(`data-${key}`, value)
      );
      // replace all __var__ variables
      element.innerHTML = element.innerHTML.replaceAll(
        templateKey,
        (match, p1) => fields[p1] || ""
      );
      // update elements with a data-field="x" attribute
      for (const field of fragment.querySelectorAll("[data-field]")) {
        console.info(
          "Updating",
          field.dataset.field,
          "to",
          fields[field.dataset.field],
          "on",
          field
        );
        field.value = fields[field.dataset.field] || "";
      }
      return fragment;
    }

    function highlight(element, className, duration = 500) {
      element.classList.add(className);
      setTimeout(() => element.classList.remove(className), duration);
    }

    function sortListItems(items) {
      const roleOrder = ["tank", "healer", "dps"];
      items
        .sort((a, b) => {
          const [iA, iB] = [
            roleOrder.indexOf(a.dataset.role),
            roleOrder.indexOf(b.dataset.role),
          ];
          return iA === iB
            ? // role is equal, sort by name:
              a.dataset.name > b.dataset.name
              ? 1
              : -1
            : iA > iB
            ? 1
            : -1;
        })
        .forEach(list.appendChild.bind(list));
    }

    // list.addEventListener("change", (event) => {
    //   console.info(event.target.dataset.field);
    //   if (event.target.dataset.field === "role") {
    //     sortListItems([...list.querySelectorAll("[data-member]")]);
    //   }
    // });

    new Sortable(list, {
      group: {
        name: "members",
        pull: true,
      },
      filter: "template",
      multiDrag: true, // Enable multi-drag
      selectedClass, // The class applied to the selected items
      onMove: function (event) {
        console.info(event);
      },
      onAdd: function (event) {
        // event.item is already inserted into the DOM
        const {
          // to, // target list
          clone, // cloned source element
          item, // dragged HTMLElement
        } = event;
        // item.innerHTML = "whhoop";
        // console.info(item.dataset)

        if (item.dataset.id) {
          const idCount = list.querySelectorAll(`[data-id="${item.dataset.id}"]`)
            .length;
          // the item itself is also counted
          if (idCount > 1) {
            item.parentNode.removeChild(item);
            // highlight(existing, highlightClass);
            return;
          }
        }

        const itemFields = item.querySelectorAll("[data-field]");
        const data = {};
        if (itemFields.length) {
          Object.assign(
            data,
            ...[...itemFields].map((e) => ({[e.dataset.field]: e.value}))
          );
        }
        item.parentNode.replaceChild(
          createItem(
            Object.assign({prefix: totalFormsInput.value}, item.dataset, data)
          ),
          item
        );

        const items = [...list.querySelectorAll("[data-member]")];
        totalFormsInput.value = items.length;

        sortListItems(items);
      },
    });
  }

  RaiderList(form.querySelector("[data-raider-list]"));
  for (const list of form.querySelectorAll("[data-member-list]")) {
    MemberList(list);
  }
}

Controllers.register("team-editor", TeamEditorController);

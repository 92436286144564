/**
 * Controllers singleton.
 * Handles registration, application and removal of controllers.
 * @type {{apply: (function(*): []), unapply: unapply, register: register}}
 */
export const Controllers = (function Controllers() {
  const registry = {};

  /**
   * Registers a new controller by name.
   * @param {String} name
   * @param {Function} controller
   */
  function register(name, controller) {
    registry[name] = controller;
  }

  /**
   * Applies all known controllers within the given container element.
   * @param {HTMLElement} container
   * @return {[]}
   */
  function apply(container) {
    const elements = container.querySelectorAll("[data-do]");
    const applied = [];
    for (const element of elements) {
      const name = element.dataset.do;
      if (name in registry) {
        const callback = registry[name](element);
        if (callback) {
          applied.push(callback);
        }
      } else {
        console.warn(`Unknown controller '${name}' on element:`, element);
      }
    }
    return applied;
  }

  /**
   * Removes the given list of applied controllers.
   * @param {Function[]} applied
   */
  function unapply(applied) {
    applied.forEach((cb) => cb());
  }

  return {register, apply, unapply};
})();

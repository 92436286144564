import Flickity from "flickity";
import "flickity-imagesloaded";

import {Controllers} from "./controllers";

/**
 * Initializes the carousel
 * @param {HTMLElement} element
 * @return {function(): *}
 */
function CarouselController(element) {
  const instance = new Flickity(element, {
    contain: true,
    autoPlay: 5000,
    wrapAround: true,
    freeScroll: false,
    imagesLoaded: true,
    prevNextButtons: false,
  });
  return () => instance.destroy();
}

Controllers.register("carousel", CarouselController);
